var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.TextDetail && Object.keys(_vm.TextDetail).length),expression:"TextDetail && Object.keys(TextDetail).length"}],staticClass:"text-detail no-close"},[(_vm.user && Object.keys(_vm.user).length)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__title",domProps:{"innerHTML":_vm._s(_vm.TextDetail.name)}})]):_vm._e(),(_vm.user && Object.keys(_vm.user).length)?_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Библиотека', link: { name: 'Library' } },
        {
          name: 'Публикации',
          link: {
            name: 'TextMaterials',
          },
        },
        {
          name: _vm.TextDetail.name,
        } ]}}):_vm._e(),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__row mt-4"},[_c('div',[(_vm.user && Object.keys(_vm.user).length)?_c('div',{staticClass:"material__tags-wrap"},[_c('div',{staticClass:"material__tags"},[(_vm.TextDetail.type)?_c('div',{staticClass:"material__tag"},[_vm._v(" "+_vm._s(_vm.TextDetail.type.name)+" ")]):_vm._e(),_vm._l((_vm.TextDetail.nosology),function(nosology){return _c('div',{key:("nos" + (nosology.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(nosology.title)}})}),_vm._l((_vm.TextDetail.disease),function(disease){return _c('div',{key:("dis" + (disease.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(disease.title)}})}),(_vm.TextDetail.is_new && false)?_c('div',{staticClass:"material__tag material__tag_new"},[_vm._v(" Новинка ")]):_vm._e()],2)]):_vm._e(),(_vm.user && Object.keys(_vm.user).length)?_c('div',{staticClass:"text-detail__body"},[_c('img',{staticStyle:{"max-width":"345px"},attrs:{"src":"https://storage.yandexcloud.net/az-most.ru-storage/media/content/pdf-pages/liz.jpg"}}),_c('div',{staticClass:"button button_pink mt-4 px-8 ml-xs-auto mr-xs-auto",staticStyle:{"width":"fit-content"},on:{"click":_vm.clickOpener}},[_vm._v(" Скачать ")])]):_vm._e(),(_vm.popup)?_c('PopupEnd',{attrs:{"eventName":'gff-pdf',"backUrl":{
              name: 'laldPdf',
            }},on:{"close":_vm.closePopup}}):_vm._e()],1)]),(false)?_c('div',{staticClass:"text-detail__buttons"},[_c('router-link',{staticClass:"text-detail__load button button_pink",attrs:{"to":{ name: 'TextMaterials' }}},[_c('span',{staticClass:"mr-2 mt-n-1"},[_c('svg',{attrs:{"width":"11","height":"18","viewBox":"0 0 11 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9 1.5L1.5 9L9 16.5","stroke":"white","stroke-width":"1.5","stroke-linecap":"square"}})])]),_vm._v(" Назад ")]),_c('div',{staticClass:"text-detail__up button button_empty-pink",on:{"click":function($event){return _vm.toTop()}}},[_vm._v(" К началу страницы "),_c('span',{staticClass:"ml-2"},[_c('svg',{attrs:{"width":"18","height":"10","viewBox":"0 0 18 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.25 8.75012L8.75 1.25012L16.25 8.75012","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])])])],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }